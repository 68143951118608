import React, {useEffect, useRef} from 'react';
import ReactHlsPlayer from 'react-hls-player';

const LeicaHlsVideo = ({paused}) => {
  const playerRef = useRef();

  function playVideo() {
    if (playerRef.current) {
      playerRef.current.play();
    }
  }

  function pauseVideo() {
    if (playerRef.current) {
      playerRef.current.pause();
    }
  }

  useEffect(() => {
    if (paused) {
      pauseVideo();
    }
    else {
      playVideo();
    }
  }, [paused]);

  return (
    <ReactHlsPlayer
      playerRef={playerRef}
      src={`https://customer-3k80imyjwt1u1qkt.cloudflarestream.com/da50d5b35c09ca4b629ea38ef420fa17/manifest/video.m3u8`}
      autoPlay={true}
      muted={true}
      loop={true}
      controls={false}
      width={'100%'}
      height={`auto`}
      playsinline={`playsinline`}
    />
  )
}

export default LeicaHlsVideo;
