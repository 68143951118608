import './App.css';
import MyVidyard from "./MyVidyard";
import LeicaHlsVideo from "./LeicaHlsVideo";
import LeicaCloudflareVideo from "./LeicaCloudflareVideo";
import {useCallback, useState} from "react";
import LeicaYoutubeVideo from "./LeicaYoutubeVideo";

function App() {
  const [paused, setPaused] = useState(false);

  const toggle = () => {
    setPaused(!paused);
  };

  const onPlay = useCallback(() => {
    console.log('onPlay triggered');
  }, [])

  return (
    <div className="App">
      <button onClick={toggle}>{paused ? 'Play' : 'Pause'}</button>

      {/*<ul className={`videos`}>*/}
      {/*  <li>*/}

      {/*    <h2>Vidyard</h2>*/}
      {/*    <div className="video-wrapper">*/}
      {/*      <MyVidyard onPlay={onPlay}*/}
      {/*                 videoUrl={`https://share.vidyard.com/watch/zsYMg9QEn9fEwEZHeKpZw3`}*/}
      {/*                 paused={paused}/>*/}
      {/*    </div>*/}
      {/*  </li>*/}
      {/*  <li>*/}

      {/*    <h2>YouTube</h2>*/}
      {/*    <div className="video-wrapper">*/}
      {/*      <LeicaYoutubeVideo paused={paused}/></div>*/}
      {/*  </li>*/}
      {/*  <li>*/}

      {/*    <h2>Cloudflare</h2>*/}
      {/*    <div className="video-wrapper">*/}
      {/*      <LeicaCloudflareVideo paused={paused}/></div>*/}
      {/*  </li>*/}
      {/*  <li>*/}

        <h2>CloudFlare + HLS</h2>
        <div className="video-wrapper">
          <LeicaHlsVideo paused={paused}/></div>
      {/*  </li>*/}
      {/*</ul>*/}
    </div>
);
}

export default App;
